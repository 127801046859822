var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "6",
      "id": "assessment-skills"
    }
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Skills: ")]), _c('ol', _vm._l(_vm.testSkills, function (t) {
    return _c('li', {
      key: t,
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), 0)])], 1), _c('v-row', {
    staticClass: "text-body-1"
  }, [_c('v-col', [_vm.minScore > 0 ? _c('span', {
    staticClass: "text-h6 mt-4"
  }, [_vm._v(" Settings: ")]) : _vm._e(), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.showMinScoreInfo = true;
      }
    }
  }, [_vm._v("Minimum Score "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "small": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v(_vm._s(_vm.mdiInformationOutline))]), _vm._v(" : " + _vm._s(_vm.minScore))], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.showMinScoreInput = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiPencil))])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "400px"
    },
    model: {
      value: _vm.showMinScoreInput,
      callback: function ($$v) {
        _vm.showMinScoreInput = $$v;
      },
      expression: "showMinScoreInput"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("Change Minimum Score")])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "New Minimum Score",
      "type": "number",
      "rules": [_vm.minScoreRule]
    },
    model: {
      value: _vm.minScore,
      callback: function ($$v) {
        _vm.minScore = $$v;
      },
      expression: "minScore"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Send Email?"
    },
    model: {
      value: _vm.sendEmail,
      callback: function ($$v) {
        _vm.sendEmail = $$v;
      },
      expression: "sendEmail"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showMinScoreInput = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.changeMinScore
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "click-outside-to-close": ""
    },
    model: {
      value: _vm.showMinScoreInfo,
      callback: function ($$v) {
        _vm.showMinScoreInfo = $$v;
      },
      expression: "showMinScoreInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("Minimum Score Information")])]), _c('v-card-text', [_vm._v(" Candidates with a score less than " + _vm._s(_vm.minScore) + " will be automatically rejected. "), _vm.sendEmail ? _c('div', [_vm._v(" And an automatic rejection email will be send. ")]) : _vm._e()]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showMinScoreInfo = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "text-body-1"
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h6",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.showInfo = true;
      }
    }
  }, [_vm._v(" Test Timings: "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "small": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v(_vm._s(_vm.mdiInformationOutline))])], 1), _c('v-dialog', {
    attrs: {
      "click-outside-to-close": ""
    },
    model: {
      value: _vm.showInfo,
      callback: function ($$v) {
        _vm.showInfo = $$v;
      },
      expression: "showInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("Test Start Information")])]), _c('v-card-text', [_vm._v(" Candidates will be able to start the test from the start time onwards. And test can't be started after the end time. ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showInfo = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Test Start Time : " + _vm._s(_vm.formatDate(_vm.testStartAt)) + " "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openStartTime
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiPencil))])], 1), _c('time-select', {
    ref: "startTime",
    model: {
      value: _vm.testStartAt,
      callback: function ($$v) {
        _vm.testStartAt = $$v;
      },
      expression: "testStartAt"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Test End Time: " + _vm._s(_vm.formatDate(_vm.testEndAt)) + " "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openEndTime
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiPencil))])], 1), _c('time-select', {
    ref: "endTime",
    model: {
      value: _vm.testEndAt,
      callback: function ($$v) {
        _vm.testEndAt = $$v;
      },
      expression: "testEndAt"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "id": "assessment-anticheats"
    }
  }, [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Anti-cheating Settings")]), _c('ul', {
    staticClass: "text-body-1"
  }, _vm._l(_vm.antiCheats, function (t) {
    return _c('li', {
      key: t
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), 0), _c('div', {
    staticClass: "test-deadline pt-5"
  }, [_c('div', {
    staticClass: "text-h6",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.showDeadlineInfo = true;
      }
    }
  }, [_vm._v(" Test Deadline: "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "small": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v(_vm._s(_vm.mdiInformationOutline))])], 1), _c('v-dialog', {
    attrs: {
      "click-outside-to-close": ""
    },
    model: {
      value: _vm.showDeadlineInfo,
      callback: function ($$v) {
        _vm.showDeadlineInfo = $$v;
      },
      expression: "showDeadlineInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("Test Deadline Information")])]), _c('v-card-text', [_vm._v(" Candidates will have to start the test within this deadline after receiving the test invite. ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showDeadlineInfo = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), !_vm.editDeadline ? _c('div', [_vm.hasDeadline ? _c('span', _vm._l(_vm.timeUnits, function (unit) {
    return _c('span', {
      key: unit.label,
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(unit.value) + " " + _vm._s(unit.label) + " ")]);
  }), 0) : _c('span', [_vm._v(" None ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.editDeadline = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiPencil))])], 1)], 1) : _vm._e(), _vm.editDeadline ? _c('div', [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_vm._l(_vm.timeUnits, function (unit) {
    return _c('v-col', {
      key: unit.label,
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": unit.label,
        "type": "number",
        "placeholder": `Enter ${unit.label}`
      },
      model: {
        value: unit.value,
        callback: function ($$v) {
          _vm.$set(unit, "value", $$v);
        },
        expression: "unit.value"
      }
    })], 1);
  }), _c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.updateDeadline
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiCheck))])], 1)], 1), _c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.removeDeadline
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiDelete))])], 1)], 1), _c('v-col', [_c('v-btn', {
    on: {
      "click": _vm.cancelDeadlineEdit
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiClose))])], 1)], 1)], 2)], 1) : _vm._e()], 1)])], 1), _vm.testByUrlEnabled ? _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-space-between",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Test Invite URL: ")]), _c('a', {
    staticClass: "text-body-1"
  }, [_vm._v(" " + _vm._s(_vm.testUrl) + " ")])]), _c('v-btn', {
    attrs: {
      "large": "",
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.copyTestUrlToClipboard
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiClipboard) + " ")]), _c('span', {
    staticClass: "success--text"
  }, [_vm._v(" Copy URL ")])], 1)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.testByUrlEnabled ? _c('div', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Test Invite URL Expiry: ")]), _vm.testByUrlEnabled ? _c('span', {
    staticClass: "text-h6"
  }, [_vm.hasUrlExpiry ? _c('span', [_vm._v(" " + _vm._s(_vm.testUrlExpiry) + " ")]) : _c('span', [_vm._v(" No url expiry set ")])]) : _vm._e(), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.editExpiryDate
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiPencil))])], 1)], 1) : _c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "primary",
      "text": "",
      "id": "add-test-url"
    },
    on: {
      "click": _vm.editExpiryDate
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiLink) + " ")]), _vm._v(" Add Test Url Invite ")], 1), _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("Test Settings")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "label": "Enable Test Invite by URL"
    },
    model: {
      value: _vm.urlInviteSelected,
      callback: function ($$v) {
        _vm.urlInviteSelected = $$v;
      },
      expression: "urlInviteSelected"
    }
  }), _vm.urlInviteSelected ? _c('v-container', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-picker"
    }
  }, [_vm._v("Set Expiry Date for Test URL")]), _c('v-date-picker', {
    attrs: {
      "id": "url-expiry-picker",
      "allowed-dates": _vm.allowFutureDates,
      "elevation": "1"
    },
    model: {
      value: _vm.testUrlExpiryEpoch,
      callback: function ($$v) {
        _vm.testUrlExpiryEpoch = $$v;
      },
      expression: "testUrlExpiryEpoch"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-time-picker"
    }
  }, [_vm._v("Set Expiry Time for Test URL")]), _c('v-time-picker', {
    attrs: {
      "id": "url-expiry-time-picker",
      "color": "accent"
    },
    model: {
      value: _vm.expiryTime,
      callback: function ($$v) {
        _vm.expiryTime = $$v;
      },
      expression: "expiryTime"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.updateEndDateTime
    }
  }, [_vm._v("Save date time")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }