<template>
  <v-card
    outlined
    rounded="lg"
    elevation="0"
    :loading="`${fetchAssessmentStatus}`"
  >
    <v-card-title class="text-h5 font-weight-bold">
      <v-btn class="px-n3" color="#122333" @click="handleBack">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="ml-5" id="assessment-title">
        {{ assessment?.getTitle() ?? 'Loading ...' }}
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <div class="mb-8 px-5">
        <v-expansion-panels v-model="infoPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              Test Info
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AssessmentDetails v-if="fetchAssessmentStatus > 0" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <candidates
        :url="$route.params.url"
        :displayCandidates="displayCandidates"
        :candidateTableLoading="candidateTableLoading"
        tableType="TEST"
        @handleCandidate="(row) => handleCandidate(row)"
        @returnToAllAssessment="returnToAllAssessment"
        id="candidate-table"
      ></candidates>
    </v-card-text>
    <v-tour name="sampleAssessmentTour" :steps="sampleAssessmentTour"></v-tour>
  </v-card>
</template>

<script>
import { mdiCloudUpload, mdiArrowLeft, mdiTrashCan, mdiMagnify } from '@mdi/js'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AssessmentDetails from '@/components/recruiter/AssessmentDetails.vue'
import { ApiCallStatus } from '@/utils/api'
import SmallConfirmation from '@/components/assessment/SmallConfirmation.vue'
import Candidates from '@/components/recruiter/Candidates.vue'

export default {
  data() {
    return {
      infoPanel: 0,
      // candidateSelection: [],
      mdiArrowLeft,
      mdiMagnify,
      displayCandidates: [],
      candidateTableLoading: true,
      sampleAssessmentTour: [
        {
          target: '#assessment-title',
          header: {
            title: 'Sample assessment',
          },
          content:
            'Learn how to view assessment details, invite candidates and more',
        },
        {
          target: '#assessment-skills',
          header: {
            title: 'Skills',
          },
          content:
            'This section lists the skills that were chosen when this assessment was created. <br/> You only need to select the skills, CodeDrills AI takes care of selecting balanced questions for each skill. All our skills are set by industry experts.',
        },
        {
          target: '#assessment-anticheats',
          header: {
            title: 'Anti-cheating settings',
          },
          content:
            'This section lists the anti-cheating settings chosen. <br/> <ul><li> Forces full screen for candidates </li> <li> Pasting external clipboard contents is not allowed </li> <li> We track and log if the candidate switches tabs </li> <li> Premium customers can enable video proctoring too </li> </ul>',
        },
        {
          target: '#assessment-invite',
          header: {
            title: 'Invite candidates',
          },
          content:
            'You can invite a candidate individually, by uploading csv or by using an invite link. Once the tour completes, try inviting yourself to try out this assessment!',
        },
        {
          target: '#candidate-sample',
          header: {
            title: 'View candidate report',
          },
          content:
            'This table provides an overview of all invited candidates. You can view the detailed report for a candidate by clicking on the candidates. Go ahead and click this candidate to view a tour of candidate report.',
        },
      ],
    }
  },
  props: {},
  computed: {
    ...mapState('recruiter', [
      'assessment',
      'allCandidates',
      'assessmentView',
      'fetchCandidateStatus',
      'fetchAssessmentStatus',
    ]),
    ...mapGetters('recruiter', ['testStatusPrettyMessage', 'testStatusColor']),
    currentAssessmentUrl() {
      return this.assessment?.getUrl()
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'fetchAssessment',
      'fetchCandidatesForTest',
      'updateCandidateStage',
      'updateCandidateRating',
    ]),
    ...mapMutations('recruiter', [
      'setCandidate',
      'setAssessment',
      'setAssessmentView',
    ]),
    returnToAllAssessment() {
      this.populateDetails()
    },
    removeSelection(type, item) {
      if (type === 'STATUS') {
        this.statusSelected = [
          ...this.statusSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'RATING') {
        this.ratingsSelected = [
          ...this.ratingsSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'STAGE') {
        this.stageSelected = [
          ...this.stageSelected.filter((v) => v !== item.value),
        ]
      } else {
      }
    },
    candidateRatingUpdate({ id, email, rating }) {
      // @TODO: Pending, event propagation wasnt stopping properly
      console.log(`candidateRatingUpdate`, { id, email, rating })
    },
    candidateStageUpdate({ id, stage }) {
      console.log(`candidateStageUpdate`, { id, stage })
      this.updateCandidateStage({
        testId: this.assessment?.getId(),
        cid: id,
        stage: stage,
      })
        .then((r) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Updated Successfully!`,
            type: 'success',
            duration: 1000,
          })
          this.populateDetails()
        })
        .catch((err) => {
          console.log('Error updating stage ..', err)
          this.$store.dispatch('notifs/addNotif', {
            text: `Error! Please refresh and try again!`,
            type: 'error',
          })
        })
    },
    handleCandidate(row) {
      console.log('Emitted Candidate is ', row)
      this.allCandidates.forEach((cd) => {
        if (cd.getId() === row.id) {
          this.setCandidate(cd)
          this.$router.push(
            `/assessments/${this.currentAssessmentUrl}/${row.id}`,
          )
        }
      })
    },
    handleBack() {
      this.$router.push('/assessments')
    },
    populateDetails() {
      return this.fetchAssessment({ url: this.$route.params.url }).then(
        (res) => {
          this.fetchCandidatesForTest({ id: this.assessment?.getId() })
            .then((r) => {
              this.displayCandidates = []
              console.log('Candidates are ...', this.allCandidates)
              this.allCandidates.forEach((cd) => {
                // do not want unknown stage
                let stage = cd?.getStage()
                if (stage == 0) stage = 1
                this.displayCandidates.push({
                  id: cd?.getId(),
                  name: cd?.getName(),
                  email: cd?.getEmail(),
                  stage,
                  percent: cd?.getOverallScore(),
                  rawScore: cd?.getRawScore(),
                  maxScore: cd?.getMaxScore(),
                  rating: cd?.getRating(),
                  status: this.testStatusPrettyMessage[cd?.getTestStatus()],
                  statusInt: cd?.getTestStatus(),
                  statusColor: this.testStatusColor[cd?.getTestStatus()],
                  startTime: cd?.getTimeInfo()?.getStartedAt(),
                  endTime: cd?.getTimeInfo()?.getEndedAt(),
                  inviteDate: cd?.getInviteDate(),
                })
              })
            })
            .catch((err) => {
              this.$store.dispatch('notifs/addNotif', {
                text:
                  err.message || 'Error Loading Candidates, please refresh!',
                type: 'error',
              })
            })
        },
      )
    },
  },
  created() {
    this.populateDetails().then(() => {
      if (
        this.assessment?.getMetaData()?.getTagsList().includes('purpose/sample')
      ) {
        this.$nextTick(() => this.$tours['sampleAssessmentTour'].start())
      }
    })
  },
  components: { AssessmentDetails, SmallConfirmation, Candidates },
  watch: {
    fetchCandidateStatus() {
      if (this.fetchCandidateStatus === ApiCallStatus.SUCCESS) {
        this.candidateTableLoading = false
      } else if (this.fetchCandidateStatus === ApiCallStatus.ERROR) {
        this.candidateTableLoading = true
        this.$store.dispatch('notifs/addNotif', {
          text: 'Error loading assessment, please reload!',
          type: 'error',
        })
      } else {
        this.candidateTableLoading = true
      }
    },
    //@TODO: fix this
    // candidateSelection() {
    //   console.log(`candidateSelection`, this.candidateSelection);
    // },
  },
}
</script>
<style scoped>
tr {
  cursor: pointer;
}
</style>
